import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = (props) => {

  const { filename, alt} = props;

  const images = useStaticQuery(graphql`
      query ImageQuery {
        data: allFile {
          edges {
            node {
              relativePath
              default: childImageSharp {
                fluid(maxWidth: 1280, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `);

    const image = images.data.edges.find(n => {
      return n.node.relativePath.includes(filename);
    });

    if (!image) {
      return null;
    }

    return (
      <Img 
        {...props}
        alt={alt}
        fluid={{
          ...image.node.default.fluid
        }} />
    )  
}

export default Image
