import React, { useContext } from "react"
import { ThemeContext } from "../../context/ThemeContext"
import Navbar from "../navbar/navbar.js"
import Footer from "../footer/footer.js"
import CookieConsent from "react-cookie-consent";
import "./style.scss"
import { CookieBarTheme} from "../../helper";

const Layout = ({ children }) => {
  const theme = useContext(ThemeContext)
  
  const cookieBarTheme = CookieBarTheme(theme.backGroundColor)

  return (
    <>
      <Navbar />
      <div className={`layout ${theme.backGroundColor}`}>{children}</div>
      <Footer />
      <CookieConsent
        style={{ background: cookieBarTheme.background, borderTop: "1px solid", color: cookieBarTheme.color }}
        buttonStyle={{
          background: cookieBarTheme.backgroundButton,
          color: cookieBarTheme.colorButton,
          fontSize: "0.9rem",
        }}
        buttonText="Accetto"
      >
        Il sito utilizza i cookie per funzionare. Utilizzando i nostri servizi
        acconsenti all'uso dei cookie.
        <a
          target="_blank"
          rel="noreferrer"
          href="http://docs.acmeproduzioni.it/acme-website/cookie-policy-it.html"
        >
          <span style={{ fontSize: "0.8rem", color:cookieBarTheme.color }}> Maggiori informazioni</span>
        </a>
      </CookieConsent>
    </>
  )
}

export default Layout
