import React, { useContext } from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import { ThemeContext } from '../../context/ThemeContext'
//import { useNavigate } from "@reach/router"

const MenuItem = ({ label, index, to }) => {
  //const navigate = useNavigate()
  const theme = useContext(ThemeContext);

  function close() {
    theme.setCurrentMenu(false);
  }

  return (
    <motion.li
      initial={{ borderBottom: "0px", height: "72px", transition: { duration: 0} }}
      animate={{ borderBottom: "1px solid", height: "72px", transition: {delay: 0.1 * index, duration: 0.1}}}
      exit={{ height: "72px", opacity: 0, transition: {delay: 0.2 }}}
    >
      <motion.div
        initial={{ x: -100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 100, opacity: 0 }}
        transition={{ delay: 0.1 * index, duration: 0.2 }}
        //onClick={() => navigate("page2")}
      >
        <Link to={to} onClick={close}>{label}</Link>
      </motion.div>
    </motion.li>
  )
}

export default MenuItem
