import React, { useEffect, useState } from "react"

const logForBbackground = {
  red: 'https://static.acmeproduzioni.it/acme/logo-black-150.webp',
  loafer: 'https://static.acmeproduzioni.it/acme/logo.svg',
  white: 'https://static.acmeproduzioni.it/acme/logo.svg',
  grey: 'https://static.acmeproduzioni.it/acme/logo-sepia-150.webp',
}

function ThemeProvider(props) {
  const [currentMenu, setCurrentMenu] = useState(false)
  const [toggleMobileMenu, setToggleMobileMenu] = useState(false)
  const [currentSubMobileMenu, setCurrentSubMobileMenu] = useState(false)
  const [visibleFooter, setVisibleFooter] = useState(true)
  const [backGroundColor, setBackGroundColor] = useState("white")
  const [textColor, setTextColor] = useState("black")
  const [logo, setLogo] = useState("https://static.acmeproduzioni.it/acme/logo.svg")
  const [originPage, setOriginPage] = useState({ label: "Home", to: "/" })
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear())

  useEffect(() => setLogo(logForBbackground[backGroundColor]), [backGroundColor])
  
  const stateToPublic = {
    currentMenu,
    setCurrentMenu,
    toggleMobileMenu,
    setToggleMobileMenu,
    currentSubMobileMenu,
    setCurrentSubMobileMenu,
    visibleFooter,
    setVisibleFooter,
    backGroundColor,
    setBackGroundColor,
    textColor,
    setTextColor,
    logo,
    setLogo,
    originPage,
    setOriginPage,
    currentYear, 
    setCurrentYear
  }

  return (
    <ThemeContext.Provider value={stateToPublic}>
      {props.children}
    </ThemeContext.Provider>
  )
}

const ThemeContext = React.createContext({})

export { ThemeContext, ThemeProvider }
