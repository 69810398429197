import React, { useContext } from "react"
import { motion, AnimatePresence } from "framer-motion"
import MenuItem from "./menuItem.js"
import { ThemeContext } from '../../context/ThemeContext'

import "./style.scss"

const Menu = ({menu}) => {
  const theme = useContext(ThemeContext);

  const variants = {
    init: {  height: "0px"},
    open: {  height: "70vh", transition:{ duration: 0.1 }},
    closed: { height: "0px", transition:{ delay: 0.2, duration: 0.2 }},
  }

  function close() {
    theme.setCurrentMenu(false)
  }

  return (
    <motion.ul
      initial={"init"}
      animate={menu && menu.children ? "open" : "closed"}
      variants={variants}
      className={menu && menu.children ? "open" : "closed"}
      onMouseLeave={close}
      id="submenu"
    >
      <AnimatePresence exitBeforeEnter>
        {menu &&
          menu.children &&
          menu.children.map((submenu, index) => (
            <MenuItem key={submenu.id} index={index + 1} {...submenu} />
          ))}
      </AnimatePresence>
    </motion.ul>
  )
}

export default Menu
