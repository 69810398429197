
import Image from "./components/image/image"
import NegativeImage from "./components/image/NegativeImage"
import SeppiaImage from "./components/image/SeppiaImage"
import RedishImage from "./components/image/RedishImage"
import BlueishImage from "./components/image/BlueishImage"

export const ImageFactory = type => {
    switch(type)
    {
        case 'negative': 
            return NegativeImage 
        case 'sepia': 
            return SeppiaImage 
        case 'red': 
            return RedishImage 
        case 'blue': 
            return BlueishImage 
        default: 
            return Image
    }     
}

export const CookieBarTheme = type => {
    switch(type)
    {
        case 'white': 
            return { background:"white", color:"black", backgroundButton: "#38b6e7", colorButton: "white" }
        case 'red': 
            return { background:"#FF001D", color:"black", backgroundButton: "#30d0b8", colorButton: "white" }
        case 'grey': 
            return { background:"#435560", color:"white", backgroundButton: "#DEDACA", colorButton: "#435560" }
        case 'loafer': 
            return { background:"#DDD9C3", color:"black", backgroundButton: "#38b6e7", colorButton: "white" } 
        default: 
            return { background:"white", color:"black", backgroundButton: "#38b6e7", colorButton: "white" }
    }     
}